import React, { useState } from 'react';
import IconeVoltar from './IconeVoltar';

function APIOficial() {
  const [token, setToken] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState('en_US'); // Adicionado estado para linguagem
  const [templateName, setTemplateName] = useState('hello_world'); // Add a new state for templateName

  const handleNumericInput = (e, setter) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const errorCodes = {
    '190': { code: '190', errormessage: 'Acesso negado!', message: 'Acesso negado. Verifique se o token esta correto e não está expirado, também pode ser um erro de permissão' },
    '402': { code: '402', errormessage: 'Elegibilidade da empresa (problema de pagamento)', message: 'Falha ao enviar mensagem devido a um ou mais erros relacionados à forma de pagamento. ' },
    '408': { code: '408', errormessage: 'A mensagem não é válida', message: 'Falha ao enviar a mensagem porque ela ficou pendente por muito tempo (por exemplo, mais de 1 dia). Isso pode acontecer ao reiniciar o cliente da WhatsApp Business API após mais de um dia de inatividade.' },
    '410': { code: '410', errormessage: 'Mensagem expirada', message: 'Mensagem não enviada durante seu TTL (tempo de vida).' },
    '429': { code: '429', errormessage: 'Limite de taxa atingido', message: 'Falha ao enviar a mensagem porque o número de telefone fez envios demais em um curto período.' },
    '430': { code: '430', errormessage: 'Certificado não assinado', message: 'Falha ao enviar a mensagem porque ocorreu um erro relacionado ao certificado, Baixe o novo certificado no Gerenciador do WhatsApp e faça o registro novamente.' },
    '432': { code: '432', errormessage: 'Incompatibilidade na identificação do certificado', message: 'Incompatibilidade na identificação do certificado.' },
    '433': { code: '433', errormessage: 'Assinatura de certificado inválida', message: 'Ocorre quando uma mensagem é enviada, mas o certificado do cliente da empresa não está assinado de forma adequada com as chaves de identidade correspondentes. Isso indica que o cliente provavelmente refez o cadastro com chaves de identidade novas, mas não concluiu todo o fluxo de criação do certificado.' },
    '470': { code: '470', errormessage: 'Mensagem de reengajamento', message: 'Falha ao enviar a mensagem porque mais de 24 horas se passaram desde a última vez que o cliente respondeu a este número.' },
    '471': { code: '471', errormessage: 'Limite de taxa de spam atingido', message: 'Falha ao enviar a mensagem porque há um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam.' },
    '472': { code: '472', errormessage: 'O número do usuário faz parte de um experimento', message: 'Falha ao enviar a mensagem porque o número de telefone do usuário faz parte de um experimento.'},
    '480': { code: '480', errormessage: 'Possível mudança de usuário', message: 'Falha ao enviar a mensagem devido a uma possível alteração no número de telefone do usuário.'},
    '500': { code: '500', errormessage: 'Erro genérico', message: 'Falha ao enviar a mensagem devido a um erro desconhecido.'},
    '501': { code: '501', errormessage: 'O tipo de mensagem não é aceito no momento.', message: 'Atualmente, as mensagens de documentos com legendas não são compatíveis.'},
    '1000': { code: '1000', errormessage: 'Erro genérico', message: 'A Meta não retornou um motivo para esse erro.'},
    '1005': { code: '1005', errormessage: 'Acesso negado', message: 'As credenciais são inválidas, Há um erro de certificado ou O app expirou e é necessário atualizar a versão. Algum destes motivos.'},
    '1008': { code: '1008', errormessage: 'Parâmetro obrigatório ausente', message: 'Um parâmetro obrigatório está ausente.'},
    '1011': { code: '1011', errormessage: 'O serviço não está pronto', message: 'A Meta só mandou isso 😖'},
    '1015': { code: '1015', errormessage: 'Número excessivo de solicitações', message: 'O limite de taxa do cliente foi atingido.'},
    '1024': { code: '1024', errormessage: 'Alteração de senha solicitada', message: 'É necessário alterar a senha'},
    '1025': { code: '1025', errormessage: 'Solicitação inválida', message: 'A solicitação não é válida.'},
    '1026': { code: '1026', errormessage: 'Destinatário indisponível', message: 'Mensagem não entregue.'},
    '1031': { code: '1031', errormessage: 'A conta do remetente está bloqueada', message: 'Sua conta foi bloqueada e não poderá enviar mensagens devido a uma violação da política de integridade. Consulte Aplicação da Política da Plataforma do WhatsApp Business para saber mais.'},
    '200': { code: '200', errormessage: 'Sucesso', message: 'Sucesso'},
    '401': { code: '401', errormessage: 'Não autorizado', message: 'Acesso negado.'},
  };

  const checkTemplate = async () => {
    setIsLoading(true);


    if (!token || !deviceId || !phoneNumber) {
      setResponse({ error: 'Por favor, preencha todos os campos.' });
      setIsLoading(false);
      return;
    }

    const url = `https://graph.facebook.com/v16.0/${deviceId}/messages`;
    const payload = {
      messaging_product: 'whatsapp',
      to: phoneNumber,
      type: 'template',
      template: {
        name: templateName,
        components: [
          {
            type: 'body'
          }
        ],
        language: {
          code: language
        }
      }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();

      if (data.messages && data.messages[0] && data.messages[0].message_status === 'accepted') {
        setResponse({ success: 'Mensagem enviada com sucesso!' });
      } else if (data.error) {
        const errorCode = data.error.code;
        const typeerror = data.error.errormessage;
        const errorMessage = errorCodes[errorCode] ? errorCodes[errorCode].message : `Ocorreu um erro, código: ${errorCode} Motivo: ${data.error.message}`;
        setResponse({ error: 'Ocorreu um erro, código:' + errorCode + {typeerror} + ' Motivo (traduzido):' + errorMessage });
      } else {
        setResponse({ error: 'Ocorreu um erro inesperado ou genérico.' });
      }
    } catch (error) {
      setResponse({ error: 'Ocorreu um erro ao verificar a API! Por favor, verifique os dados e tente novamente.' });
    } finally {
      setIsLoading(false);
      //const responseElement = document.getElementById('response');
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Verificação de API Whatsapp Business (Oficial)</h1>

      <div style={styles.inputGroup}>
        <label style={styles.label}>
          ID do Dispositivo (apenas números):
          <input
            type="text"
            value={deviceId}
            onChange={(e) => handleNumericInput(e, setDeviceId)}
            placeholder="Insira o ID do dispositivo!"
            style={styles.input}
            pattern="\d*"
          />
        </label>
      </div>

      <div style={{ ...styles.inputGroup, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '20%' }}>
          <label style={styles.label}>Linguagem:</label>
          <select value={language} onChange={(e) => setLanguage(e.target.value)} style={styles.select}>
            <option value="en_US">Inglês</option>
            <option value="pt_BR">Português</option>
          </select>
        </div>
        <div style={{ width: '75%', paddingRight: '5px' }}>
          <label style={styles.label}>Nome do Template:</label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Ex: hello_world"
            style={styles.input}
          />
        </div>
      </div>

      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Número para onde será enviado:
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => handleNumericInput(e, setPhoneNumber)}
            placeholder="Destino Ex: 555199900990099"
            style={styles.input}
            pattern="\d*"
          />
        </label>
      </div>

      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Token de acesso permanente:
          <textarea
            value={token}
            onChange={(e) => setToken(e.target.value)}
            placeholder="Insira o token de acesso permanente!"
            style={styles.textarea}
            rows={4}
          />
        </label>
      </div>

      <button
        onClick={checkTemplate}
        disabled={isLoading}
        style={styles.button}
      >
        {isLoading ? 'Aguarde, verificando...' : 'Verificar funcionamento da API'}
      </button>

      <div id="response" style={styles.response}>
        {response && response.success && (
          <div style={styles.successMessage}>{response.success}</div>
        )}
        {response && response.error && (
          <div style={styles.errorMessage}>{response.error}</div>
        )}
      </div>

      <footer style={styles.footer}>
        <div style={styles.footerLeft}>Desenvolvido por Alisson Kopp</div>
        <div style={styles.footerCenter}><IconeVoltar /></div>
        <div style={styles.footerRight}>Versão 1.2.9</div>
      </footer>
    </div>
  );
}

const styles = {
  select: {
    width: '100px', // ajustar a largura do select
    padding: '10px',
    fontSize: '1em',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '6px',
    borderRadius: '5px',
  },
  container: {
    backgroundColor: '#08041c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    paddingBottom: '60px',
  },
  title: {
    fontSize: '2em',
    textAlign: 'center',
    marginBottom: '20px',
    backgroundImage: 'linear-gradient(transparent, rgba(0, 0, 0, 0.2))',
    padding: '10px',
    borderRadius: '5px',
  },
  inputGroup: {
    width: '100%',
    maxWidth: '500px',
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '1.2em',
    marginBottom: '5px',
    backgroundImage: 'linear-gradient(transparent, rgba(0, 0, 0, 0.2))',
    padding: '5px',
    borderRadius: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.2em',
    marginTop: '20px',
    width: '100%',
    maxWidth: '500px',
    marginLeft: '25px',
  },
  response: {
    marginTop: '20px',
    width: '100%',
    maxWidth: '400px',
  },
  successMessage: {
    backgroundColor: 'white',
    border: '1px solid #4CAF50',
    color: '#4CAF50',
    padding: '10px',
    borderRadius: '5px',
  },
  errorMessage: {
    backgroundColor: 'white',
    border: '1px solid red',
    color: 'red',
    padding: '10px',
    borderRadius: '5px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#060316',
    color: 'white',
    padding: '10px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '20px',
    width: '100%',
  },
  footerLeft: {
    marginLeft: '15px',
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  footerRight: {
    marginRight: '15px',
  },
};

export default APIOficial;
