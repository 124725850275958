import React, { useState } from 'react';

function Modal({ onClose }) {
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const webhookUrl = 'https://webhook.sellflux.com/webhook/v2/form/lead/298c02d30e182a01578906003d1bba04';
    const data = { email };

    console.log('Enviando solicitação para o webhook:', webhookUrl);
    console.log('Dados a serem enviados:', data);

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      console.log('Resposta do webhook:', response);
      console.log('Corpo da resposta:', await response.text());

      if (response.ok) {
        console.log('Email enviado com sucesso!');
        setIsSending(false);
        onClose(); // Fechar o modal após o envio do email
      } else {
        console.error('Erro ao enviar email 1:', response.status, response.statusText);
        alert('Ocorreu um erro ao enviar o email para o Webhook!',<br/>,'Erro:' + response.status, response.statusText);
      }
    } catch (error) {
      console.error('Erro ao enviar email:', error.message, error.stack);
      onClose();
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <h2>Insira seu email</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu email..."
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '580px',
            }}
          required/>
          <button
            type="submit"
            style={{
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
            disabled={isSending}
          >
            {isSending ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Modal;