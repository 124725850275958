import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Exemplo de ícone, você pode usar qualquer ícone que preferir

const IconeVoltar = () => {
  const navigate = useNavigate();

  const handleVoltar = () => {
    navigate('/'); // Navega de volta para a rota principal (App.js)
  };

  return (
    <div onClick={handleVoltar} style={styles.iconContainer}>
      <FaArrowLeft size={20} style={styles.icon} />
      <span style={styles.iconText}>Voltar</span>
    </div>
  );
};

const styles = {
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'center',
    cursor: 'pointer',
    padding: '10px',
    transition: 'color 0.3s',
  },
  icon: {
    marginRight: '3px',
    color: 'white', // Cor do ícone
  },
  iconText: {
    color: 'white', // Cor do texto
    fontSize: '16px',
  },
};

export default IconeVoltar;
