// src/App.js
import React, { useState } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import APIOficial from './APIOficial';
import SMS from './SMS';
import { SiWhatsapp } from "react-icons/si";
import { SiGooglemessages } from "react-icons/si";

function App() {
  const location = useLocation();
  const [showApp, setShowApp] = useState(true);

  React.useEffect(() => {
    if (location.pathname === '/SMS' || location.pathname === '/APIOficial') {
      setShowApp(false);
    } else {
      setShowApp(true);
    }
  }, [location]);

  return (
    <div style={styles.container}>
      {showApp && (
        <div>
          <h1 style={styles.titulo}>O QUE VOCÊ DESEEJA VERIFICAR?</h1>
          <div style={styles.icons}>
            <IconLink to="/APIOficial" icon={<SiWhatsapp />} text="Envio Templates" />
            <h1>-</h1>
            <IconLink to="/SMS" icon={<SiGooglemessages />} text="Envio SMS" />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/APIOficial" element={<APIOficial />} />
        <Route path="/SMS" element={<SMS />} />
      </Routes>
    </div>
  );
}

const IconLink = ({ to, icon, text }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Link to={to} style={styles.link} onClick={handleClick}>
      <div style={{ ...styles.iconContainer, color: isActive ? '#FFFFFF' : '#FFFFFF' }}>
        {React.cloneElement(icon, { size: 50 })}
        <span style={styles.iconText}>{text}</span>
      </div>
    </Link>
  );
};

const styles = {

  icontext: {
    marginTop: '5px', // Espaçamento entre o ícone e o texto
    fontSize: '90px', // Aumenta o tamanho da fonte
  },

  titulo: {
    color: 'white',
    fontSize: '30px',
    marginBottom: '10px', // Espaçamento entre o título e os ícones
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#060316',
    color: 'white',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '100px', // Espaçamento entre os ícones
    paddingTop: '20px', // Adiciona um pouco de preenchimento
    paddingBottom: '20px',
  },
  link: {
    textDecoration: 'none', // Remove o sublinhado do link
    fontSize: '30px'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer', // Muda o cursor para indicar que é clicável
  },
  iconText: {
    marginTop: '5px', // Espaçamento entre o ícone e o texto
  },
};

export default App;
