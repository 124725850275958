import React, { useState } from 'react';
import IconeVoltar from './IconeVoltar';
import Modal from './Modal';

function VerificarSMS() {
  const [token, setToken] = useState('');
  const [phoneNumberId, setPhoneNumberId] = useState('');
  const [pin, setPin] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!token || !phoneNumberId || !pin) {
      setResponseMessage({ error: 'Por favor, preencha todos os campos.' });
      setIsLoading(false);
      return;
    }

    const data = {
      "messaging_product": "whatsapp",
      "pin": pin
    };

    try {
      const response = await fetch(`https://graph.facebook.com/v20.0/${phoneNumberId}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage({ type: 'success', message: 'PIN enviado com sucesso!' });
        setIsModalOpen(false);
      } else {
        const errorMessage = (
          <ul style={styles.response}>
            <li><strong>Erro:</strong> {result.error.message || 'Desconhecido'}</li>
            <li><strong>Tipo:</strong> {result.error.type || 'Desconhecido'}</li>
            <li><strong>Código:</strong> {result.error.code || 'Desconhecido'}</li>
            <li><strong>Subcódigo:</strong> {result.error.error_subcode || 'Desconhecido'}</li>
            <li><strong>Título do Usuário:</strong> {result.error.error_user_title || 'Desconhecido'}</li>
            <li><strong>Mensagem do Usuário:</strong> {result.error.error_user_msg || 'Desconhecida'}</li>
            <li><strong>fbtrace_id:</strong> {result.error.fbtrace_id || 'Desconhecido'}</li>
          </ul>
        );
        setResponseMessage({ type: 'error', message: errorMessage });
      }
    } catch (error) {
      setResponseMessage({
        type: 'error',
        message: `Erro na requisição: ${error.message}`
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          {/* Conteúdo do modal */}
        </Modal>
      )}
      <div style={styles.container}>
        <h1 style={styles.title}>Verificar SMS</h1>
        <form onSubmit={handleSubmit}>
          <label style={styles.label}>
            Token:
          </label>
          <div style={styles.inputGroup}>
            <input style={styles.input} required type="text" value={token} onChange={(e) => setToken(e.target.value)} />
          </div>
          <br />
          <label style={styles.label}>
            ID do número de telefone
          </label>
          <div style={styles.inputGroup}>
            <input style={styles.input} required type="text" value={phoneNumberId} onChange={(e) => setPhoneNumberId(e.target.value)} />
          </div>
          <br />
          <label style={styles.label}>
            PIN:
          </label>
          <div style={styles.inputGroup}>
            <input style={styles.input} required type="text" value={pin} onChange={(e) => setPin(e.target.value)} />
          </div>
          <br />
          <button type="submit" style={styles.button} disabled={isLoading}>{isLoading ? 'Aguarde, enviando...' : 'Verificar funcionamento da API'}</button>
        </form>
        {responseMessage && (
          <div style={{ marginLeft: '90px', marginRight: '70px', margin: '20px', maxWidth: '600px', width: '100%', backgroundColor: responseMessage.type === 'success' ? '#d4edda' : '#f8d7da', color: responseMessage.type === 'success' ? '#155724' : '#721c24', padding: '10px', borderRadius: '5px' }}>
            {responseMessage.message}
          </div>
        )}
        <footer style={styles.footer}>
          <div style={styles.footerLeft} title='Front-end por: Alisson Kopp'>Desenvolvido por Gabriel Gaier</div>
          <div style={styles.footerCenter}><IconeVoltar /></div>
          <div style={styles.footerRight}>Versão 1.2.9</div>
        </footer>
      </div>
    </div>
  );
}

const styles = {
  select: {
    width: '100px', // ajustar a largura do select
    padding: '10px',
    fontSize: '1em',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '6px',
    borderRadius: '5px',
  },
  container: {
    backgroundColor: '#08041c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    boxSizing: 'border-box',
    //position: 'relative',
    paddingBottom: '60px',
  },
  title: {
    fontSize: '2em',
    textAlign: 'center',
    marginBottom: '20px',
    backgroundImage: 'linear-gradient(transparent, rgba(0, 0, 0, 0.2))',
    padding: '10px',
    borderRadius: '5px',
  },
  inputGroup: {
    width: '100%',
    maxWidth: '600px',
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '1.2em',
    marginBottom: '5px',
    backgroundImage: 'linear-gradient(transparent, rgba(0, 0, 0, 0.2))',
    padding: '5px',
    borderRadius: '5px',
  },
  input: {
    width: '600px',
    maxWidth: '600px',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
  },
  inputToken: {
    width: '600px',
    maxWidth: '600px',
    height: '50px',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    color: '#333',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.2em',
    marginTop: '20px',
    width: '100%',
    maxWidth: '600px',
    marginLeft: '8px',
    paddingBottom: '10px',
  },
  response: {
    padding: '40px',
    marginTop: '20px',
    width: '100%',
    maxWidth: '400px',
  },
  successMessage: {
    backgroundColor: 'white',
    border: '1px solid #4CAF50',
    color: '#4CAF50',
    padding: '10px',
    borderRadius: '5px',
  },
  errorMessage: {
    backgroundColor: 'white',
    border: '1px solid red',
    color: 'red',
    padding: '10px',
    borderRadius: '5px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#060316',
    color: 'white',
    padding: '10px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '20px',
    width: '100%',
  },
  footerLeft: {
    marginLeft: '15px',
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  footerRight: {
    marginRight: '15px',
  },
};

export default VerificarSMS;